<script setup lang="ts">
import { useForm } from 'vee-validate';
import { object, string } from 'yup';
import { toTypedSchema } from '@vee-validate/yup';
import type {
  BridgeAuthResponse,
  LoginCredentials,
  LoginFormFields
} from '@/types/auth';
import type { ApiResponse } from '@/composables/api/types';
import type { BaseAttrsScheme } from '@/types/form';

interface Props {
  onLogin: (
    credentials: LoginCredentials
  ) => Promise<ApiResponse<BridgeAuthResponse>>;
  initialValues?: Partial<LoginFormFields>;
  baseAttrs?: Partial<BaseAttrsScheme<LoginFormFields>>;
}

const props = withDefaults(defineProps<Props>(), {
  initialValues: () => {
    return {};
  },
  baseAttrs: () => {
    return {};
  }
});

const config = useRuntimeConfig();

const { errors, handleSubmit, defineField, setFieldError } =
  useForm<LoginFormFields>({
    initialValues: props.initialValues,
    validationSchema: toTypedSchema(
      object({
        email: string()
          .email('Must be a valid email')
          .required('Email address is required'),
        password: string().required('Password is required')
      })
    )
  });

const [email, emailAttrs] = defineField('email', {
  props: () => props.baseAttrs?.email
});
const [password, passwordAttrs] = defineField('password', {
  props: () => props.baseAttrs?.password
});

const loading = ref(false);

const resetPasswordLink = computed(() => {
  return `${config.public.bridge128Url}/reset-password`;
});

async function onSuccess(values: LoginFormFields) {
  if (!loading.value) {
    loading.value = true;

    const { error } = await props.onLogin(toRaw(values));

    if (error?.data?.message) {
      setFieldError('email', error.data.message);
    }

    loading.value = false;
  }
}

const onSubmit = handleSubmit(onSuccess);
</script>

<template>
  <form
    novalidate
    class="w-full grid grid-cols-2 gap-2.5"
    @submit.prevent="onSubmit"
  >
    <Field
      label="Email"
      name="email"
      class-name="col-span-2 !mb-0"
      :error="errors.email"
    >
      <Input
        v-model="email"
        v-bind="emailAttrs"
        placeholder="Enter your email"
        type="email"
      />
    </Field>
    <Field
      label="Password"
      name="password"
      class-name="col-span-2 !mb-0"
      :error="errors.password"
    >
      <template #label="{ label, id, required }">
        <div class="flex items-center justify-between">
          <Label v-if="label" :for="id" :required="required">
            {{ label }}
          </Label>
          <span class="inline-flex items-center mb-2">
            <NuxtLink
              :to="resetPasswordLink"
              class="mr-1 inline-block text-brand-medium-gray text-sm leading-[18px] font-medium hover:underline"
            >
              Forgot Password?
            </NuxtLink>
            <Tooltip
              placement="top"
              content="For your security, please ensure your new password is strong and unique. It will be used as your credentials for both Handle and Bridge128."
              class-name="max-w-64"
            >
              <Icon
                name="icons8:info"
                size="20"
                class="text-brand-medium-gray"
              />
            </Tooltip>
          </span>
        </div>
      </template>
      <template #default>
        <Input
          v-model="password"
          v-bind="passwordAttrs"
          placeholder="Enter your password"
          type="password"
        />
      </template>
    </Field>
    <div class="col-span-2 flex flex-col items-center mt-3">
      <slot name="submit" :loading="loading" :on-submit="onSubmit">
        <Button type="submit" :loading="loading" class-name="w-[172px]">
          Log In
        </Button>
      </slot>
    </div>
  </form>
</template>
